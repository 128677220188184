import React from 'react'
import styled from 'styled-components'

const Image = styled.img`
  position: absolute;
  right: ${props => (props.left ? 'unset' : '0')};
  left: ${props => (props.left ? '0' : 'unset')};
  bottom: 0;
  pointer-events: none;
  height: calc(100% - 10px);
  width: unset;
  opacity: ${props => (props.opacity ? props.opacity : '0.8')};
`

export const BackgroundImage = ({ src, alt, left, opacity }) => (
  <Image src={src} alt={alt} left={left} opacity={opacity} />
)
