import { useStaticQuery, graphql } from 'gatsby'

export const usePricedServicesData = () => {
  const { pricedServicesData } = useStaticQuery(
    graphql`
      {
        pricedServicesData: sanityPricedServices {
          title
          _rawDescription
          servicesMiniTitle
          pricing {
            price
            time
            description
            timelyProductId
          }
          altText
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          additionalOneTitle
          _rawAdditionalOneDescription
          additionalTwoTitle
          _rawAdditionalTwoDescription
          _rawAdditionalTwoA
          _rawAdditionalTwoB
          otherOneTitle
          _rawOtherOneDescription
          otherOneImage {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          otherOneAltText
          otherTwoTitle
          _rawOtherTwoDescription
          otherTwoImage {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          otherTwoAltText
        }
      }
    `
  )
  return { pricedServicesData }
}
