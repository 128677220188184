import { useStaticQuery, graphql } from 'gatsby'

export const useGeneralData = () => {
  const { generalData } = useStaticQuery(
    graphql`
      {
        generalData: sanityGeneral {
          websiteTitle
          cta
          ctaGiftVoucher
          altText
          cancellationPolicy
          creditCard
          altTextLogo
          testimonialsTitle
          image {
            asset {
              fluid(maxWidth: 4000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          logoRegular {
            asset {
              fluid(maxWidth: 200) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
          logoWhite {
            asset {
              fluid(maxWidth: 200) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
      }
    `
  )
  return { generalData }
}
