import PropTypes from 'prop-types'
import styled from 'styled-components'

export const ContentContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding-top: ${props => props.theme.spacing[props.py] || 'unset'};
  padding-bottom: ${props => props.theme.spacing[props.py] || 'unset'};
  padding-left: ${props => props.theme.spacing.sm};
  padding-right: ${props => props.theme.spacing.sm};
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: ${props => props.theme.spacing.xs};
  }
`

ContentContainer.propTypes = {
  py: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
}
