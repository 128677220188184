import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledFilledButton = styled(props => <Link {...props} />)`
  text-align: center;
  font-size: 1rem;
  display: table;
  width: auto;
  background-color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 3px;
  padding: 15px 30px;
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  transition: background-color 0.2s, border 0.2s;

  &:hover {
    background-color: ${props => props.theme.colors.primaryDark};
    border: 1px solid ${props => props.theme.colors.primaryDark};
  }

  &.-dark {
    background-color: ${props => props.theme.colors.primaryDark};
    border: 1px solid ${props => props.theme.colors.primaryDark};

    &:hover {
      background-color: ${props => props.theme.colors.primaryDarkest};
      border: 1px solid ${props => props.theme.colors.primaryDarkest};
    }
  }

  &.-center {
    margin: 0 auto;
  }

  &.-mobileCenter {
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      margin: 20px auto 0;
    }
  }
`

export const FilledButton = ({ to, children, className, state }) => (
  <StyledFilledButton to={to} className={className} state={state}>
    {children}
  </StyledFilledButton>
)

const StyledLinkButton = styled(props => <Link {...props} />)`
  text-align: center;
  font-size: 1rem;
  display: table;
  // width: fit-content;
  margin: 0 auto 0 0;
  text-decoration: none;
  position: relative;
  color: ${props => props.theme.colors.primary};
  padding-bottom: 5px;

  &:before {
    content: '';
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0px;
    opacity: 0.3;
    transition: 0.3s;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }

  &.-center {
    margin: 0 auto;
  }

  &.-left {
    margin: 0 auto 0 0;
  }

  &.-right {
    margin: 0 0 0 auto;
  }

  &.-white {
    color: ${props => props.theme.colors.white};

    &:before {
      border-bottom: 1px solid ${props => props.theme.colors.white};
    }
  }

  &.-black {
    color: ${props => props.theme.colors.black};

    &:before {
      border-bottom: 1px solid ${props => props.theme.colors.black};
    }
  }
`

export const LinkButton = ({ to, children, className }) => (
  <StyledLinkButton to={to} className={className}>
    {children}
  </StyledLinkButton>
)
