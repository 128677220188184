import { useStaticQuery, graphql } from 'gatsby'

export const useContactData = () => {
  const { contactData } = useStaticQuery(
    graphql`
      {
        contactData: sanityContact {
          phone
          email
          facebookText
          facebook
        }
      }
    `
  )
  return { contactData }
}
