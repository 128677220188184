import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import FBLogoPath from '../images/HMC_FBIcon_White.png'
import { ContentContainer, Row } from './utils'
import { FilledButton, LinkButton, Image } from './common'

import { useContactData, useGeneralData } from '../hooks'

const Styledfooter = styled.footer`
  background: ${props => props.theme.colors.greyDark};
  padding-top: ${props => props.theme.spacing.md};
  padding-bottom: ${props => props.theme.spacing.md};

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding-top: ${props => props.theme.spacing.xs};
    padding-bottom: ${props => props.theme.spacing.xs};
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-top: 0;
    padding-bottom: 0;
  }

  ${Row} {
    > div,
    > a {
      margin: 20px 0;
    }
  }
`

const FooterLink = styled(Link)`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  text-align: center;
  padding: 0 0 20px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    order: 5;
    margin-bottom: 0 !important;
  }

  &.site-logo {
    max-width: 200px;
    width: 100%;
    margin: 20px auto !important;
  }
`

const FooterMid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.theme.spacing.xs};
`

const FooterDetails = styled.div`
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #fff;

  > a {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;

    > img {
      height: 20px;
      margin-left: 10px;
    }
  }
`

const WebBrewBuild = styled.div`
  font-size: 0.9rem;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;

  a {
    color: #777;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Footer = () => {
  const { contactData } = useContactData()
  const { generalData } = useGeneralData()
  return (
    <Styledfooter>
      <ContentContainer>
        <Row col={5} alignCenter>
          <div>
            <LinkButton to="/about" className="-white -center">
              About
            </LinkButton>
          </div>
          <div>
            <LinkButton to="/services" className="-white -center">
              Services
            </LinkButton>
          </div>
          <div>
            <LinkButton to="/contact" className="-white -center">
              Contact
            </LinkButton>
          </div>
          <div>
            <LinkButton to="/gift-vouchers" className="-white -center">
              Gift Vouchers
            </LinkButton>
          </div>
          <div>
            <FilledButton to="/book" className="-center">
              Book Now
            </FilledButton>
          </div>
        </Row>
        <FooterMid>
          <FooterLink className="site-logo" to="/">
            <Image fluid={generalData.logoWhite.asset.fluid} alt={generalData.altTextLogo} loading="eager" />
          </FooterLink>
          <FooterDetails>
            <a href={`mailto:${contactData.email}`}>{contactData.email}</a>
          </FooterDetails>
          <FooterDetails>{contactData.phone}</FooterDetails>
          <FooterDetails>
            <a target="_blank" rel="noopener noreferrer" href={contactData.facebook}>
              {contactData.facebookText} <img src={FBLogoPath} alt={contactData.facebookText} />
            </a>
          </FooterDetails>
        </FooterMid>
        <WebBrewBuild>
          <a href="https://webbrew.co.nz" target="_blank" rel="noopener">
            Built by Web Brew
          </a>
        </WebBrewBuild>
      </ContentContainer>
    </Styledfooter>
  )
}

export default Footer
