import React from 'react'
import styled from 'styled-components'

import { FilledButton } from './button'
import { Container, ContentContainer, Row } from '../utils'
import { BackgroundImage } from './backgroundImage'

import HMCBRLogo from '../../images/HMC_Background_Dark_Flipped.svg'

import { useGeneralData } from '../../hooks'

const Text = styled.h2`
  color: ${props => props.theme.colors.white};
  margin: 0;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    text-align: center;
  }
`

export const Cta = () => {
  const { generalData } = useGeneralData()
  return (
    <Container bg="primary">
      <ContentContainer py="md">
        <BackgroundImage src={HMCBRLogo} alt="HMC Logo flair" left />
        <Row col={2} alignCenter>
          <Text>{generalData.cta}</Text>
          <FilledButton to="/book" className="-dark -mobileCenter">
            Book a Massage Today
          </FilledButton>
        </Row>
      </ContentContainer>
    </Container>
  )
}
