/* ---------------------------------------------------------- */
/* COLORS                                                     */
/* ---------------------------------------------------------- */
const colors = {
  primary: '#4caf50',
  primaryLighter: '#f1f9f1',
  primaryDark: '#3c8c40',
  primaryDarkest: '#307033',
  grey: '#f9f9f9',
  greyDark: '#222',
  greyMedDark: '#666',
  white: '#ffffff',
  black: '#000000',
  greyLight: '#999999',
  greyLighter: '#f4f4f4',
}

/* ---------------------------------------------------------- */
/* BREAKPOINTS                                                */
/* ---------------------------------------------------------- */
const breakpoints = {
  sm: '640px',
  md: '832px',
  lg: '1024px',
  xl: '1440px',
}

/* ---------------------------------------------------------- */
/* SPACING                                                    */
/* ---------------------------------------------------------- */
const spacing = {
  xs: '20px',
  sm: '40px',
  md: '80px',
  lg: '120px',
  xl: '140px',
}

export default { colors, breakpoints, spacing }
