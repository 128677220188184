import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import 'typeface-lato'
import 'typeface-overlock'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  html {
    font-size: 14px;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      font-size: 12px;
    }
    
    body {
      font-size: 1rem;
      font-family: 'Lato', sans-serif;

      header {
        position: relative;
        z-index: 1080;
      }

      main, footer {
        position: relative;
        z-index: 99;

        * {
          z-index: 500;
        }
      }

      h1 {
        font-family: 'Overlock', 'Lato', sans-serif;
        font-size: 4.2rem;
        font-display: auto;

        @media (max-width: ${props => props.theme.breakpoints.sm}) {
          font-size: 34px;
        }
      }

      h2 {
        font-family: 'Overlock', 'Lato', sans-serif;
        font-size: 3rem;
        font-weight: 400; 

        @media (max-width: ${props => props.theme.breakpoints.sm}) {
          font-size: 28px;
        }
      }

      h3 {
        font-family: 'Overlock', 'Lato', sans-serif;
        font-size: 2rem;
        font-weight: 200;
      }

      h4 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0 0 10px;
      }

      p, li {
        font-weight: 400;
        line-height: 1.5;
        margin: 0 0 10px;
      }

      h5 {
        font-size: 1rem;
        font-weight: 400;
      }

      h6 {
        font-size: 1rem;
        font-weight: 600;
        color: ${props => props.theme.colors.primary};
        margin: 0 0 10px;
        text-transform: uppercase;
      }

      h1,
      h2,
      h3 {
        line-height: 1.2;
        margin: 0 0 15px;
      }

      h4,
      h5,
      h6,
      p {
        line-height: 1.5;
      }
    }

    * {
      box-sizing: border-box;
    }
  } 
`

export default GlobalStyle
