import React from 'react'
import styled from 'styled-components'

import { FilledButton } from './button'
import { Container, ContentContainer, Row } from '../utils'
import { BackgroundImage } from './backgroundImage'

import HMCBRLogo from '../../images/HMC_Background_Dark_Flipped.svg'

import { useGeneralData } from '../../hooks'

const Text = styled.h2`
  color: ${props => props.theme.colors.black};
  margin: 0;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    text-align: center;
  }
`

export const CtaGiftVoucher = () => {
  const { generalData } = useGeneralData()
  return (
    <Container bg="greyLighter">
      <ContentContainer py="md">
        <Row col={2} alignCenter>
          <Text>{generalData.ctaGiftVoucher}</Text>
          <FilledButton to="/gift-vouchers" className="-grey -mobileCenter">
            Buy a Gift Voucher
          </FilledButton>
        </Row>
      </ContentContainer>
    </Container>
  )
}
