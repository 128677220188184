import React from 'react'
import PortableText from '@sanity/block-content-to-react'

export const FancyText = ({ node, blocks }) => (
  <PortableText
    serializers={{
      types: {
        code: (
          <pre data-language={node.language}>
            <code>{node.code}</code>
          </pre>
        ),
      },
    }}
    blocks={blocks}
  />
)
