import React from 'react'
import styled from 'styled-components'

import { Container, ContentContainer, Row } from '../utils'
import { BackgroundImage } from './backgroundImage'

import HMCBRLogo from '../../images/HMC_Background_Dark.svg'

const Title = styled.h1`
  text-align: center;
  color: ${props => props.theme.colors.white};
  margin: 0;
`

const PageTitleRow = styled(props => <Row {...props} />)`
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 0 10px;
  }
`

export const PageTitle = ({ title }) => (
  <Container bg="primary">
    <ContentContainer py="sm">
      <BackgroundImage src={HMCBRLogo} alt="HMC Logo flair" />
      <PageTitleRow col={1}>
        <Title>{title}</Title>
      </PageTitleRow>
    </ContentContainer>
  </Container>
)
