import { useStaticQuery, graphql } from 'gatsby'

export const useAboutData = () => {
  const { aboutData } = useStaticQuery(
    graphql`
      {
        aboutData: sanityAbout {
          aboutCompanyTitle
          _rawAboutCompanyText
          aboutTherapistTitle
          _rawAboutTherapistText
          altTextPortrait
          portrait {
            asset {
              fluid(maxWidth: 1000, maxHeight: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          images {
            altText
            image {
              asset {
                fluid(maxWidth: 1000, maxHeight: 1000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    `
  )
  return { aboutData }
}
