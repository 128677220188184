import { Link } from 'gatsby'
import React, { useState } from 'react'
import { IoMdMenu } from 'react-icons/io'
import styled from 'styled-components'

import { ContentContainer, Row } from './utils'
import { FilledButton, LinkButton, Image } from './common'

import { useGeneralData } from '../hooks'

const Styledheader = styled.header`
  position: relative;
  padding: 20px 0 0;
  background: ${props => (props.location.pathname === '/' ? 'none' : props.theme.colors.primary)};
  margin-bottom: ${props => (props.location.pathname === '/' ? '-140px' : '0')};

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0;

    ${ContentContainer} {
      padding: 0;
    }
  }
`

const Nav = styled(props => <Row {...props} />)`
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`

const NavLink = styled(Link)`
  color: ${props => props.theme.colors.black};
  text-decoration: none;
  text-align: center;
  padding: 0 10px;
  font-size: 1em;

  &.site-logo {
    width: 200px;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding-bottom: 20px;
    }
  }
`

const MobileNav = styled.nav`
  display: none;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: block;
  }

  .MobileNavHead {
    position: relative;
    background: ${props => (props.location.pathname === '/' ? 'none' : props.theme.colors.primary)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 20px;
    z-index: 99;

    .site-logo {
      width: 150px;
      padding: 0;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        max-width: 120px;
      }
    }

    .MobileNavCTA {
      margin-left: auto;

      @media (max-width: 400px) {
        display: none;
      }
    }

    .MobileNavButton {
      cursor: pointer;
      background: transparent;
      color: ${props => (props.location.pathname === '/' ? props.theme.colors.black : props.theme.colors.white)};
      border-radius: 3px;
      border-color: ${props => (props.location.pathname === '/' ? '#ddd' : 'rgba(255, 255, 255, 0.3)')};
      border-width: 1px;
      border-style: solid;
      padding: 0;
      height: 44px;
      width: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px 0 15px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .MobileNavMenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    background: ${props => props.theme.colors.white};
    left: 0;
    right: 0;
    opacity: 0;
    z-index: -1;
    transform: translateY(-100%);
    transition: transform 0.25s, opacity 0.25s, z-index 0s 0.25s;

    &.active {
      opacity: 1;
      z-index: 1;
      transform: translateY(0);
      box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
      transition: transform 0.25s, opacity 0.25s, z-index 0s;
    }

    ${NavLink} {
      padding: 20px 0;
    }

    &.active {
      opacity: 1;
      z-index: 1;
      transform: translateY(0);
    }

    .mobile__bookButton {
      display: none;
    }

    @media (max-width: 400px) {
      .mobile__bookButton {
        margin-top: 10px;
        display: block;
      }
    }
  }
`

const Header = ({ location }) => {
  const [isMobileNav, setIsMobileNav] = useState(false)

  const mobileNavActiveClass = isMobileNav ? 'active' : ''
  const { generalData } = useGeneralData()

  return (
    <Styledheader location={location}>
      <ContentContainer>
        <Nav col={5} alignCenter>
          <div>
            <LinkButton to="/about" className={`${location.pathname === '/' ? '-black' : '-white'} -center`}>
              About
            </LinkButton>
          </div>
          <div>
            <LinkButton to="/services" className={`${location.pathname === '/' ? '-black' : '-white'} -center`}>
              Services
            </LinkButton>
          </div>
          <NavLink className="site-logo" to="/">
            {location.pathname === '/' ? (
              <Image fluid={generalData.logoRegular.asset.fluid} alt={generalData.altTextLogo} />
            ) : (
              <Image fluid={generalData.logoWhite.asset.fluid} alt={generalData.altTextLogo} />
            )}
          </NavLink>
          <div>
            <LinkButton to="/contact" className={`${location.pathname === '/' ? '-black' : '-white'} -center`}>
              Contact
            </LinkButton>
          </div>
          <div>
            <FilledButton to="/book" className={`${location.pathname !== '/' && '-dark'} -center`}>
              Book Now
            </FilledButton>
          </div>
        </Nav>
        <MobileNav location={location}>
          <div className="MobileNavHead">
            <NavLink className="site-logo" to="/">
              {location.pathname === '/' ? (
                <Image fluid={generalData.logoRegular.asset.fluid} alt={generalData.altTextLogo} />
              ) : (
                <Image fluid={generalData.logoWhite.asset.fluid} alt={generalData.altTextLogo} />
              )}
            </NavLink>
            <FilledButton className={`MobileNavCTA ${location.pathname !== '/' && '-dark'}`} to="/book">
              Book Now
            </FilledButton>
            <div className="MobileNavButton" onClick={() => setIsMobileNav(!isMobileNav)}>
              <IoMdMenu />
            </div>
          </div>
          <div className={`MobileNavMenu ${mobileNavActiveClass}`}>
            <NavLink to="/about">About Us</NavLink>
            <NavLink to="/services">Services</NavLink>
            <NavLink to="/contact">Contact</NavLink>
            <FilledButton to="/book" className="mobile__bookButton">
              Book Now
            </FilledButton>
          </div>
        </MobileNav>
      </ContentContainer>
    </Styledheader>
  )
}

export default Header
