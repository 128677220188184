import { useStaticQuery, graphql } from 'gatsby'

export const useTestimonialsData = () => {
  const { testimonialsQuery } = useStaticQuery(
    graphql`
      {
        testimonialsQuery: allSanityTestimonials {
          edges {
            node {
              name
              testimonial
            }
          }
        }
      }
    `
  )
  const testimonialsData = testimonialsQuery.edges.map(({ node }) => ({
    name: node.name,
    testimonial: node.testimonial,
  }))
  return { testimonialsData }
}
