import PropTypes from 'prop-types'
import styled from 'styled-components'

export const Row = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: ${props => (props.alignCenter ? 'center' : 'none')};
  justify-content: space-between;
  width: 100%;
  padding-bottom: ${props => props.theme.spacing[props.pb]};
  padding-top: ${props => props.theme.spacing[props.pt]};

  > * {
    width: calc((100% - ${props => (props.gap ? props.gap : '0')}px) / (${props => props.col}));

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-wrap: wrap;
    padding-bottom: ${props => props.theme.spacing.xs};
    padding-top: ${props => props.theme.spacing.xs};
  }
`

Row.propTypes = {
  pb: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  pt: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
}
