import styled from 'styled-components'

export const VerticalLine = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 1px !important;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }

  div {
    display: none;
    width: 1px !important;
    height: 100%;
    background-color: ${props => props.bg};

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      display: inline-block;
    }
  }
`
